<template>
  <div class="content_body font_14">
    <div
      class="nav_title font_15 border_box"
      style="border-bottom: 3px solid #e4e4e4"
    >
      跟进任务规则设置
    </div>
    <div class="back_f8 padlt_10 padrt_20 marbm_15 dis_flex flex_y_center">
      <div class="label dis_flex flex_y_center" style="margin-top: 0">
        跟进任务配置
      </div>
    </div>
    <el-row>
      <el-col :offset="1" :span="20">
        <div class="color_999 line_26 marbm_15">
          注: 跟进一般由公司分配给顾客的所属顾问负责
        </div>
      </el-col>
    </el-row>

    <div class="nav_header">
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch(1)"
          >
            <el-form-item label="">
              <el-input
                clearable
                placeholder="输入分群名称搜索"
                v-model="searchName"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="add(1)"
                >添加分群</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :offset="1" :span="20">
        <div class="table_box">
          <el-table :data="tabData" class="border_left border_right">
            <el-table-column label="分群名称" prop="Name"></el-table-column>
            <el-table-column label="日常维护(次/月)" prop="maintenance">
              <template slot-scope="scope">
                <el-input
                  v-model.number="scope.row.maintenance"
                  size="small"
                  class="inputValue"
                  clearable
                  onkeyup="this.value=this.value=this.value.replace(/\D/g,'')"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="生日当天是否增加一次跟进" prop="follow">
              <template slot-scope="scope">
                <el-radio :label="true" v-model="scope.row.follow">是</el-radio>
                <el-radio :label="false" v-model="scope.row.follow"
                  >否</el-radio
                >
              </template>
            </el-table-column>
            <el-table-column label="节假日当天是否增加一次跟进" prop="holiday">
              <template slot-scope="scope">
                <el-radio :label="true" v-model="scope.row.holiday"
                  >是</el-radio
                >
                <el-radio :label="false" v-model="scope.row.holiday"
                  >否</el-radio
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  @click="delete (scope.row, scope.$index)"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pad_15 text_right">
            <el-pagination
              background
              v-if="paginations.total > 1"
              @current-change="handleCurrentChange"
              :current-page.sync="paginations.page"
              :page-size="paginations.page_size"
              :layout="paginations.layout"
              :total="paginations.total"
            ></el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 节假日配置 -->
    <div class="back_f8 padlt_10 padrt_20 marbm_15 dis_flex flex_y_center">
      <div class="label dis_flex flex_y_center" style="margin-top: 0">
        节假日配置
      </div>
    </div>
    <div class="nav_header">
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch(2)"
          >
            <el-form-item label="">
              <el-input
                clearable
                placeholder="输入节假日名称搜索"
                v-model="searchName"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="add(2)"
                >添加节假日</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :offset="1" :span="20">
        <div class="table_box">
          <el-table :data="holidayData" class="border_left border_right">
            <el-table-column label="节假日名称" prop="Name"></el-table-column>
            <el-table-column label="时间" prop="Time"></el-table-column>
            <el-table-column label="属性" prop="follow"></el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  @click="delete (scope.row, scope.$index)"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pad_15 text_right">
            <el-pagination
              background
              v-if="holidayPaginations.total > 1"
              @current-change="CurrentChange"
              :current-page.sync="holidayPaginations.page"
              :page-size="holidayPaginations.page_size"
              :layout="holidayPaginations.layout"
              :total="holidayPaginations.total"
            ></el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="text_center martp_25 save_btn">
      <el-button type="primary" size="small" class="font_16">保存</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "FollowTask",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      searchName: "",
      tabData: [
        {
          Name: "李亚森",
          maintenance: "30",
          follow: false,
          holiday: true,
        },
      ],
      holidayData: [
        {
          Name: "春节",
          Time: "农历1月1号",
          follow: "系统",
        },
      ],
      paginations: {
        page: 1,
        total: 1,
        page_size: 10,
        layout: "total, prev, pager, next,jumper",
      },
      holidayPaginations: {
        page: 1,
        total: 1,
        page_size: 10,
        layout: "total, prev, pager, next,jumper",
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    handleSearch(type) {},
    delete() {},
    add() {},
    handleCurrentChange(page) {
      this.paginations.page = page;
    },
    CurrentChange() {},
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {},
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>
 
<style lang='scss'>
.content_body {
  position: relative;
  .nav_title {
    padding: 20px;
    width: calc(100% + 30px);
    transform: translate(-15px, -15px);
  }
  .label::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 15px;
    margin: 10px 10px;
    background-color: #018dff;
  }
  .inputValue {
    width: 120px;
  }
  .table_box {
    width: 1200px;
    margin-bottom: 40px;
  }
  .save_btn {
    // position: fixed;
    // bottom: 0;
    // width: 100%;
    // left: 0;
    border-top: 3px solid #409eff;
    padding: 20px 0;
  }
}
</style>